import { defineStore } from '#src/stores/state-wrapper.js';

import { useEntityStore, entityKeyGen } from '#src/stores/entity.js';
import { useIndividualStore, individualKeyGen } from '#src/stores/individual.js';
import { useInstanceSettingsStore } from '#src/stores/instance-settings.js';
import { useExistingCoverageStore } from '#src/stores/existing-coverage.js';
import {
  INSURED_TYPES,
  useInsuredStore,
  usePrimaryInsuredStore,
  useJointInsuredStore,
  getPayorRoleForInsured,
} from '#src/stores/insured.js';
import { useQuotingStore } from '#src/stores/quoting.js';
import { useFlowStore } from '#src/stores/flow.js';
import { useCaseStore } from '#src/stores/case.js';
import { useStepOwnerStore } from '#src/stores/step-owner.js';

import {
  CONTINGENT_BENEFICIARY,
  INSURED,
  JOINT_INSURED,
  OWNER,
  PRIMARY_BENEFICIARY,
  SPOUSE,
} from '#src/structures/Role.js';

import {
  currencyFormatter,
  generateValidations,
  isNotDefined,
  parseErrorMessage,
  generateUuid,
} from '#src/util/helpers.js';
import {
  CONCEPTS,
  PRODUCTS,
  ProductType,
  categoryToConstantProduct,
} from '#src/structures/ProductType.js';
import { setAgentFromRequest } from '#src/structures/Agent.js';

import { HttpIndividualsService } from '#src/services/http-individuals.service.js';
import { EntityService } from '#src/services/entity.service.js';
import { CoverageService } from '#src/services/coverage.service.js';
import { EappService } from '#src/services/eapp.service.js';
import { QuotesService } from '#src/services/quotes.service.js';
import { ProductsService } from '#src/services/products.service.js';

import { JOINT_INSURED_PARTNER_DISCOUNT } from '#src/data/quoteParams.js';
import { BEST_TIME_TO_CALL_OPTIONS, PURPOSE_OPTIONS } from '#src/data/refer-info.js';
import { useSavableProperty } from '#src/composables/savable-property.composable.js';
import { validateBoolean, validateText } from '#src/composables/savable-property-validators.mjs';

const clone = (v) => JSON.parse(JSON.stringify(v));

export const getPartyStore = (partyKey, pinia) => {
  if (Object.values(INSURED_TYPES).includes(partyKey)) return useInsuredStore(partyKey, pinia);
  if (partyKey.includes('entity')) return useEntityStore(partyKey, pinia);
  return useIndividualStore(partyKey, pinia);
};

// Deprecated
export const eAppValidations = (eApp) => {
  const childAgeValidation = (index) => ({
    zeroOrGreaterThan: {
      v: ({ calc_children }) =>
        calc_children[index].age >= 0 && ![null, undefined].includes(calc_children[index].age),
      message: 'Must be greater than 0',
    },
  });

  const additionalComputedValidations = {};

  if (eApp.calc_children.length > 0) {
    eApp.calc_children.forEach((c, index) => {
      additionalComputedValidations[`calc_child_${index}`] = childAgeValidation(index);
    });
  }

  return {
    use_eachother_as_beneficiaries: {
      trueOrFalse: {
        v: ({ use_eachother_as_beneficiaries }) =>
          [false, true].includes(use_eachother_as_beneficiaries),
        message: 'Must be yes or no',
      },
    },
    calc_mortgage_balance: {
      zeroOrGreaterThan: {
        v: ({ calc_mortgage_balance }) => calc_mortgage_balance >= 0,
        message: 'Must be greater than 0',
      },
    },
    calc_non_mortgage_debts: {
      zeroOrGreaterThan: {
        v: ({ calc_non_mortgage_debts }) => calc_non_mortgage_debts >= 0,
        message: 'Must be greater than 0',
      },
    },
    calc_children: {
      allHaveDefinedAges: {
        v: ({ calc_children }) => {
          if (!calc_children?.length) return true;
          return calc_children.every(({ age }) => age >= 0 && age <= 17);
        },
        message: 'Must be between 0 and 17',
      },
    },
    calc_annual_beneficiary_income: {
      zeroOrGreaterThan: {
        v: ({ calc_annual_beneficiary_income }) => calc_annual_beneficiary_income >= 0,
        message: 'Must be greater than 0',
      },
    },
    calc_beneficiary_income_years: {
      zeroOrGreaterThan: {
        v: ({ calc_beneficiary_income_years }) => calc_beneficiary_income_years >= 0,
        message: 'Must be greater than 0',
      },
    },
    calc_existing_life_insurance: {
      zeroOrGreaterThan: {
        v: ({ calc_existing_life_insurance }) => calc_existing_life_insurance >= 0,
        message: 'Must be greater than 0',
      },
    },
    calc_savings: {
      zeroOrGreaterThan: {
        v: ({ calc_savings }) => calc_savings >= 0,
        message: 'Must be greater than 0',
      },
    },

    ...additionalComputedValidations,
  };
};

export function useEappStore(pinia, hot) {
  const individualService = new HttpIndividualsService(pinia);
  const entityService = new EntityService(pinia);
  const coverageService = new CoverageService(pinia);
  const quotesService = new QuotesService(pinia);
  const eAppService = new EappService(pinia);
  const productsService = new ProductsService(pinia);

  return defineStore('electronic-application', {
    state: () => ({
      has_logged_in: false,
      has_created_eapp: false,
      advanced_health: false,
      assistance: { topPriority: '', ltcType: '' },
      apply_link: null,
      calc_beneficiary_income_years: 10,
      calc_annual_beneficiary_income: 50000,
      calc_children: [],
      calc_existing_life_insurance: 0,
      calc_mortgage_balance: 145000,
      calc_non_mortgage_debts: 32000,
      calc_savings: 24000,
      existing_coverages: [],
      id: null,
      uuid: null,

      demo_name: useSavableProperty({
        requestMap: 'demo_name',
        group: 'root',
        rules: {
          validLength: validateText(() => useEappStore(pinia).demo_name.model, {
            minLength: 1,
            maxLength: 255,
          }),
        },
      }),
      purpose_of_insurance: useSavableProperty({
        requestMap: 'purpose_of_insurance',
        group: 'root',
        rules: {
          validLength: {
            v: () => {
              const store = useEappStore(pinia);
              return PURPOSE_OPTIONS.includes(store.purpose_of_insurance.model);
            },
            message: 'Must be between 1 and 50 characters long',
          },
        },
      }),
      best_time_to_call: useSavableProperty({
        requestMap: 'best_time_to_call',
        group: 'root',
        rules: {
          validLength: {
            v: () => {
              const store = useEappStore(pinia);
              return BEST_TIME_TO_CALL_OPTIONS.includes(store.best_time_to_call.model);
            },
            message: 'Must be between 1 and 50 characters long',
          },
        },
      }),
      refer_notes: useSavableProperty({
        requestMap: 'refer_notes',
        group: 'root',
        rules: {
          validLength: validateText(() => useEappStore(pinia).refer_notes.model, {
            minLength: 1,
            maxLength: 255,
          }),
        },
      }),
      send_refer_text: useSavableProperty({
        requestMap: 'send_refer_text',
        group: 'root',
        rules: {
          isTrueOrFalse: validateBoolean(() => useEappStore(pinia).send_refer_text.model),
        },
      }),
      round_robin_agent_id: useSavableProperty({
        requestMap: 'round_robin_agent_id',
        group: 'root',
        rules: {
          validLength: {
            v: () => {
              const instance = useInstanceSettingsStore(pinia);
              const store = useEappStore(pinia);
              return instance.round_robin_agents.some(
                (v) =>
                  v.value === store.round_robin_agent_id.model ||
                  store.round_robin_agent_id.model === null,
              );
            },
            message: 'Please specify an agent',
          },
        },
      }),
      other_pending_insurance_amount_to_be_accepted: useSavableProperty({
        requestMap: 'other_pending_insurance_amount_to_be_accepted',
        group: 'root',
        rules: {
          isNumber: {
            v: () => {
              const eapp = useEappStore(pinia);
              if (isNotDefined(eapp.other_pending_insurance_amount_to_be_accepted.model)) {
                return false;
              }
              return !isNaN(eapp.other_pending_insurance_amount_to_be_accepted.model);
            },
            message: 'Must be a number',
          },
          isMoreThanMin: {
            v: () => {
              const eapp = useEappStore(pinia);
              if (!eapp.otherPendingAmountToBeAcceptedMin) return true;
              return (
                eapp.otherPendingAmountToBeAcceptedMin <=
                eapp.other_pending_insurance_amount_to_be_accepted.model
              );
            },
            message: () => {
              const eapp = useEappStore(pinia);
              `Must be greater than or equal to  ${currencyFormatter(
                eapp.otherPendingAmountToBeAcceptedMin,
              )}`;
            },
          },
          isLessThanPendingCoverage: {
            v: () => {
              const eapp = useEappStore(pinia);

              if (!eapp.otherPendingAmountToBeAcceptedMax) return true;
              return (
                eapp.other_pending_insurance_amount_to_be_accepted.model <=
                eapp.otherPendingAmountToBeAcceptedMax
              );
            },
            message: () => {
              const eapp = useEappStore(pinia);
              `Must be less than or equal to  ${currencyFormatter(
                eapp.otherPendingAmountToBeAcceptedMax,
              )}`;
            },
          },
          isGreaterThanZero: {
            v: () => {
              const eapp = useEappStore(pinia);
              return eapp.other_pending_insurance_amount_to_be_accepted.model >= 0;
            },
            message: `Must be at least 0`,
          },
        },
      }),
      tia: useSavableProperty({
        requestMap: 'tia',
        group: 'root',
        rules: {
          isTrueOrFalse: validateBoolean(() => useEappStore(pinia).tia.model),
        },
      }),

      refer: false,
      npn: '',
      parent_url: '',
      parties: [],
      // this beast should have its own store...
      selectedQuote: {
        illustration_id: null,
        benefit_period: null,
        carrier: {
          am_best_rating: null,
          approval_time: null,
          avatar_url: null,
          name: null,
          strife_disclosure: null,
          signature_age: null,
        },
        cash_benefit_percentage: null,
        chronic_illness_rider: null,
        death_benefit: null,
        digital_application_text: { description: null, title: null },
        discount_text: null,
        distributions_cumulative: null,
        distributions_yearly_level: null,
        elimination_period_text: null,
        elimination_period: null,
        exam_details: { details: null },
        exam_required: null,
        filter_chronic_illness_and_ltc: null,
        face_amount: null,
        graded_death_benefit: null,
        guaranteed_issue: null,
        home_health_care_waiver: null,
        id: null,
        real_id: null,
        real_joint_id: null,
        illustration_available: null,
        income_end_age: null,
        income_solve: null,
        income_start_age: null,
        inflation_percentage: null,
        initial_death_benefit: null,
        insured_quote_id: null,
        joint_insured_premium: null,
        joint_insured_quote_id: null,
        joint_insured_validated_build: { max_weight: null },
        joint_insured_validated_rating_error: null,
        joint_insured_validated_rating: null,
        joint_insured_validated_smoking_types: null,
        joint_waiver_of_premium: null,
        joint_waiver_of_premium_text: null,
        lapse_protection_to_age: null,
        living_benefits_details: { details: null, pdf: null },
        loader: null,
        ltc_amount: null,
        ltc_rider_percentage: null,
        ltc_rider: null,
        ltc_pool_of_money_values: [],
        monthly_benefit_values: [],
        max_ltc_amount: null,
        mec_year: null,
        mode: null,
        monthly_benefit: null,
        non_guaranteed_account_values: null,
        non_guaranteed_lapse_protection_to_age: null,
        one_partner_discount_percentage: null,
        partner_discount_percentage: null,
        partner_discount_text: null,
        partner_discount: null,
        pay_duration: null,
        pool_of_money: null,
        preferred_carrier: null,
        preferred: null,
        premium: null,
        product: {
          active_years: null,
          advisor_use_only: null,
          carrier_e_delivery: null,
          category: null,
          conversion: null,
          has_advisor_guide: null,
          has_consumer_guide: null,
          has_underwriting_guide: null,
          id: null,
          instant_aps: null,
          multiple_policies: null,
          name: null,
          payment_required: null,
          product_details: null,
          require_payment: null,
          riders: { chronic_illness: null, critical_illness_rider: null },
          tax_free_income_pdf_url: null,
          vitality_rider: null,
        },
        rank: null,
        rate: null,
        save_age_text: null,
        save_age_suggestion: null,
        shared_care_text: null,
        shared_care: null,
        solve: null,
        state: null,
        type: null,
        validated_build: { max_weight: null },
        validated_rating_error: null,
        validated_rating: null,
        vitality_level: null,
        waiver_of_premium_text: null,
      },
      sharable_apply_link: null,
      signer_status: null,
      step_is_saving: false,
      resume_carrier_eapp: null,
      limit_carrier_eapp_to_insured: null,
      use_eachother_as_beneficiaries: null,
    }),
    getters: {
      isAdvisorUseOnly() {
        return this.selectedQuote.product.advisor_use_only === true;
      },
      isMultiplePolicies() {
        return this.isDualInsured && this.selectedQuote?.product?.multiple_policies;
      },
      carrierName() {
        return this.selectedQuote?.carrier?.name;
      },
      selectedQuoteCategories() {
        return categoryToConstantProduct(this.selectedQuote?.product?.category);
      },
      pendingExistingCoverages() {
        return this.existing_coverages.filter((id) => {
          const coverage = useExistingCoverageStore(id, pinia);
          return coverage.status.model === 'Pending';
        });
      },
      hasPendingExistingCoverages() {
        return Boolean(this.pendingExistingCoverages.length);
      },
      otherPendingAmountToBeAcceptedMin() {
        return (
          this.selectedQuote?.death_benefit ||
          this.selectedQuote?.face_amount ||
          this.selectedQuote?.initial_death_benefit ||
          0
        );
      },
      otherPendingAmountToBeAcceptedMax() {
        if (!this.hasPendingExistingCoverages) return null;
        return this.pendingExistingCoverages.reduce((accumulator, coverageKey) => {
          const coverage = useExistingCoverageStore(coverageKey, pinia);
          return coverage.face_amount.model + accumulator;
        }, this.otherPendingAmountToBeAcceptedMin);
      },
      isDualInsured() {
        const quoting = useQuotingStore(pinia);
        const jointlyApplying =
          quoting.rollbackPoint.partner_discount === JOINT_INSURED_PARTNER_DISCOUNT;

        const dualApplicantTypes = [PRODUCTS.LTC, PRODUCTS.LINKED_BENEFIT];
        const canJointlyApply = dualApplicantTypes.includes(quoting.rollbackPoint.selected_type);
        return jointlyApplying && canJointlyApply;
      },
      validation() {
        return generateValidations(eAppValidations(this), this);
      },
      beneficiariesByInsuredId() {
        const insured = usePrimaryInsuredStore(pinia);
        const jInsured = useJointInsuredStore(pinia);

        const byInsuredId = {};

        if (insured.id) byInsuredId[insured.id] = [];
        if (jInsured.id) byInsuredId[jInsured.id] = [];

        this.parties.forEach((partyKey) => {
          const store = getPartyStore(partyKey, pinia);
          const primaryRoleForId = store.role_for_id[PRIMARY_BENEFICIARY];
          const contingentRoleForId = store.role_for_id[CONTINGENT_BENEFICIARY];
          if (primaryRoleForId) byInsuredId[primaryRoleForId].push(partyKey);

          if (contingentRoleForId) byInsuredId[contingentRoleForId].push(partyKey);
        });

        if (
          jInsured.id &&
          (insured.roles[PRIMARY_BENEFICIARY] || insured.roles[CONTINGENT_BENEFICIARY])
        ) {
          byInsuredId[jInsured.id].push(INSURED_TYPES.INSURED);
        }

        if (
          (jInsured.id && jInsured.roles[PRIMARY_BENEFICIARY]) ||
          jInsured.roles[CONTINGENT_BENEFICIARY]
        ) {
          byInsuredId[insured.id].push(INSURED_TYPES.JOINT);
        }
        return byInsuredId;
      },
      existingCoveragesByInsuredId() {
        const groups = {};

        this.existing_coverages.forEach((id) => {
          const coverage = useExistingCoverageStore(id, pinia);
          let coverageBelongsTo = coverage.insured_id;
          if (!groups[coverageBelongsTo]) groups[coverageBelongsTo] = [];
          groups[coverageBelongsTo].push(id);
        });

        return groups;
      },
      ownerKey() {
        const ownerStep = useStepOwnerStore(pinia);
        // If users may not specify who the owner is, then it's assumed to be the insured (such as accidental death)
        if (ownerStep.inactive) return INSURED_TYPES.INSURED;

        return [INSURED_TYPES.INSURED, INSURED_TYPES.JOINT, ...this.parties].find(
          (p) => getPartyStore(p, pinia).roles[OWNER],
        );
      },
    },
    actions: {
      initializeCalculatorFromEapp(value) {
        const {
          calc_beneficiary_income_years,
          calc_annual_beneficiary_income,
          calc_children,
          calc_existing_life_insurance,
          calc_mortgage_balance,
          calc_non_mortgage_debts,
          calc_savings,
        } = value;

        if (calc_beneficiary_income_years || calc_beneficiary_income_years === 0) {
          this.calc_beneficiary_income_years = calc_beneficiary_income_years;
        }

        if (calc_annual_beneficiary_income || calc_annual_beneficiary_income === 0) {
          this.calc_annual_beneficiary_income = calc_annual_beneficiary_income;
        }
        if (Array.isArray(calc_children)) {
          this.calc_children.push(...calc_children);
        }

        if (calc_existing_life_insurance || calc_existing_life_insurance === 0) {
          this.calc_existing_life_insurance = calc_existing_life_insurance;
        }

        if (calc_mortgage_balance || calc_mortgage_balance === 0) {
          this.calc_mortgage_balance = calc_mortgage_balance;
        }

        if (calc_non_mortgage_debts || calc_non_mortgage_debts === 0) {
          this.calc_non_mortgage_debts = calc_non_mortgage_debts;
        }

        if (calc_savings) {
          this.calc_savings = calc_savings;
        }
      },
      initializeQuoteData(primaryQuote, jointQuote) {
        if (primaryQuote) {
          const quote = clone(primaryQuote);
          quote.real_id = primaryQuote.id;
          if (jointQuote) {
            quote.real_joint_id = jointQuote.id;
            quote.joint_insured_premium = jointQuote.premium;
            quote.joint_insured_validated_rating = jointQuote.validated_rating;
          }
          this.setSelectedQuote(quote);
        }
      },
      initializeFromEApp(value) {
        if (!value) return;
        this.parent_url = value.url;
        this.id = value.id;
        this.uuid = value.uuid;
        this.assistance.topPriority = value.assist?.top_priority;
        this.assistance.ltcType = value.assist?.ltc_type;

        this.purpose_of_insurance.load(value.purpose_of_insurance);
        this.best_time_to_call.load(value.best_time_to_call);
        this.refer_notes.load(value.refer_notes);
        this.send_refer_text.load(value.send_refer_text);
        this.tia.load(value.tia);
        this.demo_name.load(value.demo_name);

        this.refer = value.refer;
        this.sharable_apply_link = value.sharable_apply_link;
        this.signer_status = value.signer_status;
        this.limit_carrier_eapp_to_insured = value.limit_carrier_eapp_to_insured;
        this.resume_carrier_eapp = value.resume_carrier_eapp;
        this.other_pending_insurance_amount_to_be_accepted.load(
          value.other_pending_insurance_amount_to_be_accepted,
        );

        this.face_amount = value.face_amount;
        this.mode = value.mode;
      },
      initializeInsured(type, value) {
        const insuredStore = useInsuredStore(type, pinia);
        insuredStore.setFromEApp(value);
      },
      initializeParties(rawParties) {
        this.parties.splice(0, this.parties.length);
        rawParties.forEach((party) => {
          const isInsured = party.application_roles.find(({ role }) => role === INSURED);
          const isJointInsured = party.application_roles.find(({ role }) =>
            [JOINT_INSURED, SPOUSE].includes(role),
          );

          if (isInsured) {
            this.initializeInsured(INSURED_TYPES.INSURED, party);
          } else if (isJointInsured) {
            this.initializeInsured(INSURED_TYPES.JOINT, party);
          } else if (party.type === 'Individual') {
            const individualStore = useIndividualStore(individualKeyGen(party.id), pinia);
            individualStore.initializeFromEapp(party);
            this.parties.push(individualStore.id);
            return;
          } else if (party.type === 'Entity') {
            const entityStore = useEntityStore(entityKeyGen(party.id), pinia);
            entityStore.initializeFromEapp(party);
            this.parties.push(entityStore.id);
          }
        });
      },
      initializeExistingCoverages(rawExistingCoverages) {
        rawExistingCoverages.forEach((rawCoverage) => {
          const coverage = useExistingCoverageStore(`existing-coverage-${rawCoverage.id}`, pinia);
          coverage.initializeFromEapp(rawCoverage);
          this.existing_coverages.push(`existing-coverage-${rawCoverage.id}`);
        });
      },
      initializeUseEachotherAsBeneficiaries() {
        const insuredStore = usePrimaryInsuredStore(pinia);
        const insuredId = insuredStore.id;
        const insuredBenes = this.beneficiariesByInsuredId[insuredId];

        const jointInsuredStore = useJointInsuredStore(pinia);
        const jointInsuredId = jointInsuredStore.id;
        const jointInsuredBenes = this.beneficiariesByInsuredId[jointInsuredId];

        if (!insuredBenes?.length && !jointInsuredBenes?.length) return;

        if (insuredBenes?.length !== 1 || jointInsuredBenes?.length !== 1) {
          this.use_eachother_as_beneficiaries = false;
          return;
        }

        const jointIsBene = insuredBenes[0] === INSURED_TYPES.JOINT;
        const insuredIsBene = jointInsuredBenes[0] === INSURED_TYPES.INSURED;

        this.use_eachother_as_beneficiaries = jointIsBene && insuredIsBene;
      },
      // TODO: Delete and replace with createOwnerParty / createBeneficiaryParty
      async createParty({ type, beneficiary_amount = null, role, insured_id }) {
        if (!['individual', 'business', 'trust'].includes(type)) throw 'Missing Party Type';

        const application_roles_attributes = [];
        if (Array.isArray(role)) {
          role.forEach((r) => {
            const roles_attributes = { role: r };
            if (beneficiary_amount) {
              roles_attributes.beneficiary_amount = +(beneficiary_amount / 100).toFixed(2);
            }

            if (insured_id) roles_attributes.insured_id = insured_id;
            application_roles_attributes.push(roles_attributes);
          });
        } else {
          const roles_attributes = { role };
          if (beneficiary_amount) {
            roles_attributes.beneficiary_amount = +(beneficiary_amount / 100).toFixed(2);
          }

          if (insured_id) roles_attributes.insured_id = insured_id;
          application_roles_attributes.push(roles_attributes);
        }

        return this.createNewParty({ type, application_roles_attributes });
      },
      async createPayorParty({ type, insured_id }) {
        let insuredId = insured_id;
        if (!Array.isArray(insuredId)) insuredId = [insuredId];

        const application_roles_attributes = [];
        insuredId.forEach((i) => {
          application_roles_attributes.push({
            role: getPayorRoleForInsured(i, pinia),
            insured_id: i,
          });
        });

        return this.createNewParty({ type, application_roles_attributes });
      },
      async createNewParty({ type, application_roles_attributes }) {
        if (!['individual', 'business', 'trust'].includes(type)) throw 'Missing Party Type';

        const body = {
          addresses_attributes: [],
          application_roles_attributes,
          irrevocable: undefined,
        };

        let address_type = 'business';

        if (type === 'individual') {
          address_type = 'home';
        }

        body.addresses_attributes.push({ address_type });

        if (type === 'trust') body.irrevocable = false;

        let party, model;
        if (type === 'individual') {
          party = await individualService.createIndividual(body);
          model = useIndividualStore(individualKeyGen(party.id), pinia);
        } else {
          party = await entityService.createEntity(body);
          model = useEntityStore(entityKeyGen(party.id), pinia);
        }

        model.initializeFromEapp(party);
        this.parties.push(model.id);
        return model.id;
      },
      async deletePartyOrRole({ partyKey, role }) {
        const store = getPartyStore(partyKey, pinia);
        const roles = Object.values(store.roles).filter(Boolean);

        if (roles.length > 1 || Object.values(INSURED_TYPES).includes(partyKey)) {
          return await store.deleteRole(role);
        }
        await store.deleteParty();
        const index = this.parties.findIndex((v) => v === partyKey);
        if (index > -1) this.parties.splice(index, 1);
      },
      async clearAllBeneficiaries(role) {
        for (let partyKey of this.parties) {
          const store = getPartyStore(partyKey, pinia);
          if (store.roles[role]) {
            await this.deletePartyOrRole({ partyKey, role });
          }
        }
      },
      setSelectedQuote(newPrimaryQuote) {
        this.selectedQuote = clone(newPrimaryQuote);
      },
      updateSelectedQuote(newPrimaryQuote) {
        const quote = { ...newPrimaryQuote, primary: true };
        const quoting = useQuotingStore(pinia);

        let type = quoting.params.selected_type;
        if (quoting.params.selected_type === CONCEPTS.ALL_UL) {
          type = quoting.params.subtype || quoting.params.selected_type;
        }
        const product = new ProductType(type);

        const insured = usePrimaryInsuredStore(pinia);
        const params = {
          ...product.eAppParser({
            ...quote,
            inflation_percentage:
              quote.inflation_percentage === undefined ? null : quote.inflation_percentage,
            face_amount: quote.initial_death_benefit,
            term_duration: quote.product.active_years,
            premium: quote.premium,
            age: insured.age,
          }),
        };
        quoting.initializeQuoteParams(params);
        quoting.setRollbackPoint();
        return quote;
      },
      async updateOrCreateEapp(quote) {
        if (this.id) return this.saveQuoteStep(quote);
        await this.createAndPrefill(quote);
        return { created: true };
      },
      setQuoteMetadata(quote) {
        if (!quote?.product?.id) return;
        this.primaryQuoteId = quote.product?.id;
        this.setSelectedQuote(quote);
      },
      async createCoverage(insured_id) {
        const coverage = await coverageService.createCoverage({ insured_id });
        const coverageStore = useExistingCoverageStore(`existing-coverage-${coverage.id}`, pinia);
        coverageStore.initializeFromEapp(coverage);
        this.existing_coverages.push(`existing-coverage-${coverage.id}`);
      },
      async deleteCoverage(id) {
        const coverage = useExistingCoverageStore(id, pinia);
        const coverageId = coverage.id;
        const coverageIndex = this.existing_coverages.indexOf(id);

        await coverageService.deleteCoverage(coverageId);

        this.existing_coverages.splice(coverageIndex, 1);
        coverage.$dispose();
      },
      async updateEapp(reqObj) {
        const { data_requirements } = await eAppService.updateElectronicApplication(reqObj);
        const flow = useFlowStore(pinia);
        flow.updateStepRequirements(data_requirements);
      },
      async saveAttributes(attributes = []) {
        if (!attributes.length || !this.id) return;
        const reqObj = {};
        const reqAttrs = [];
        attributes.forEach((a) => {
          if (a in this) {
            if (this[a]?.requestMeta) {
              const key = this[a].requestMeta.requestMap;
              const value = this[a].requestMeta.format();
              reqAttrs.push(a);
              reqObj[key] = value;
            } else {
              reqObj[a] = this[a];
            }
          }
        });

        if (!Object.keys(reqObj).length) return;
        const reqUuid = generateUuid();
        if (reqAttrs.length) {
          reqAttrs.forEach((a) => {
            this[a].requestMeta.requests.push({
              id: reqUuid,
              initiatedAt: new Date().getTime(),
              payload: JSON.stringify(reqObj),
            });
          });
        }
        let res, errorMessage;
        try {
          res = await this.updateEapp(reqObj);
        } catch (e) {
          errorMessage = parseErrorMessage(e);
        } finally {
          reqAttrs.forEach((a) => {
            this[a].requestMeta.deleteRequest(reqUuid);

            if (errorMessage) this[a].requestMeta.errorMessage = errorMessage;
          });
        }

        return res;
      },
      validateCitizenship(visaType) {
        return productsService.validateCitizenship(this.selectedQuote.product?.id, visaType);
      },
      async ensureRoleMatchForDualApplicants() {
        // lets say partner discount is one and partner is spouse, then they change it to joint
        // partner discount that comes through is "both"

        const jointInsured = useJointInsuredStore(pinia);
        if (jointInsured.id && this.isDualInsured) {
          if (jointInsured.roles[JOINT_INSURED]) return;
          // joint should have joint insured role
          const id = await individualService.giveJointInsuredRole(jointInsured.id);
          jointInsured.roles[SPOUSE] = null;
          jointInsured.roles[JOINT_INSURED] = id;
        } else if (jointInsured.id) {
          if (jointInsured.roles[SPOUSE]) return;
          // joint should have spouse role
          const id = await individualService.giveSpouseRole(jointInsured.id);
          jointInsured.roles[JOINT_INSURED] = null;
          jointInsured.roles[SPOUSE] = id;
        }
      },
      notifyUnlicensedAgent(not_licensed_state) {
        return eAppService.notifyUnlicensedAgent(not_licensed_state);
      },
      setIds({ id = null, uuid = null }) {
        try {
          if (id) sessionStorage.setItem('eapp_id', id);
          else sessionStorage.removeItem('eapp_id');

          if (uuid) sessionStorage.setItem('eapp_uuid', uuid);
          else sessionStorage.removeItem('eapp_uuid');
        } catch (e) {
          // do nothing
        }
        this.id = id;
        this.uuid = uuid;
      },
      async fetchEapp({ birthdate = null } = {}) {
        let body = {};
        if (birthdate) body.birthdate = birthdate;
        const eApp = await eAppService.fetchEapp(body);
        pinia._s.forEach((store) => {
          // keep current step + instance settings
          if (['instance-settings', 'flow', 'test-helpers'].includes(store.$id)) return;
          store.$reset();
        });
        await this.initializeApplicationData(eApp, birthdate);
        return eApp;
      },
      async resumeEApp(resume, id, uuid) {
        const eApp = await eAppService.resumeEApp(resume, id, uuid);
        await this.initializeApplicationData(eApp);
        const instance = useInstanceSettingsStore(pinia);
        instance.isAgent = true;
        const flow = useFlowStore(pinia);
        await flow.navToStep({ step: eApp.step });
        return true;
      },
      async createElectronicApplication(quote = {}, additionalCreateOptions = {}) {
        const quoting = useQuotingStore(pinia);
        const flow = useFlowStore(pinia);
        const instance = useInstanceSettingsStore(pinia);
        const insured = usePrimaryInsuredStore(pinia);

        const body = {
          advanced_health: this.advanced_health,
          approved_domain_id: instance.id,
          assist: {
            enabled: true,
            top_priority: this.assistance.topPriority,
            ltc_type: this.assistance.ltcType,
          },
          calc_annual_beneficiary_income: this.calc_annual_beneficiary_income,
          calc_beneficiary_income_years: this.calc_beneficiary_income_years,
          calc_children: this.calc_children,
          calc_existing_life_insurance: this.calc_existing_life_insurance,
          calc_mortgage_balance: this.calc_mortgage_balance,
          calc_non_mortgage_debts: this.calc_non_mortgage_debts,
          calc_savings: this.calc_savings,
          chat: {
            initiated_step: null,
            enabled: Boolean(instance.chat),
            opened: false,
            created_via_chat: null,
          },
          demo_name: this.demo_name.model,
          initiated_url: this.parent_url,
          insured: insured.getCreateData(quote.id),
          owner: { same_as_insured: true },
          rank: quote.rank,
          refer: this.refer,
          selected_type: quoting.params.selected_type,
          step: flow.step,
          url: this.parent_url,
          prefill_params: instance.prefill,
        };

        if (additionalCreateOptions?.created_without_quote) {
          body.created_without_quote = true;
        }

        if (instance.metadata.value) {
          body.metadata = instance.metadata.value;
        }
        if (instance.test) body.test = true;

        if (quoting.params.selected_type === CONCEPTS.ALL_UL) {
          body.selected_type = quoting.params.subtype || CONCEPTS.ALL_UL;
        }

        if (quoting.params.partner_discount) {
          let key = 'joint_insured';
          if (quoting.params.partner_discount === 'one') key = 'spouse';

          const joint = useJointInsuredStore(pinia);
          body[key] = joint.getCreateData(quote.joint_insured_quote_id);
        } else if (quote.joint_insured_quote_id) {
          body.joint_insured = { quote_id: quote.joint_insured_quote_id };
        }

        if (this.round_robin_agent_id.model) {
          body.round_robin_agent_id = this.round_robin_agent_id.model;
        }

        if (additionalCreateOptions?.questions?.length) {
          body.approved_domain_answers_attributes = additionalCreateOptions.questions.map((q) => ({
            approved_domain_question_id: q.id,
            answer: q.answer,
          }));
        }

        if (pinia.state.value['chat']) {
          const chatStore = (await import('#src/stores/chat.js')).useChatStore(pinia);
          body.chat.initiated_step = chatStore.initiatedStep;
          body.chat.enabled = Boolean(instance.chat);
          body.chat.opened = chatStore.opened;
          body.chat.created_via_chat = chatStore.createdFromChat;
        }

        if (body.chat.created_via_chat || this.refer) {
          body.state = quoting.params.state;
        }

        if (this.refer) {
          if (instance.show_refer_text) body.send_refer_text = this.send_refer_text.model;
          body.refer_notes = this.refer_notes.model;
          body.purpose_of_insurance = this.purpose_of_insurance.model;
          body.best_time_to_call = this.best_time_to_call.model;
        }

        if (instance.referData) {
          body.referrer_id = instance.referData.referrer_id;
          body.referrer_type = instance.referData.referrer_type;
        }

        const eApp = await eAppService.createElectronicApplication(body);
        const currentQuote = clone(quote);
        await this.initializeEAppData(eApp);
        currentQuote.real_id = this.selectedQuote.real_id;
        currentQuote.real_joint_id = this.selectedQuote.real_joint_id;
        this.$patch({ selectedQuote: quote });

        this.has_created_eapp = true;

        return { uuid: this.uuid, apply_link: this.apply_link };
      },
      async createAndPrefill(quote = {}, additionalCreateOptions = {}) {
        const instance = useInstanceSettingsStore(pinia);
        await this.createElectronicApplication(quote, additionalCreateOptions);

        if (instance.primaryParams && Object.keys(instance.primaryParams).length) {
          const { PrefillService } = await import('#src/services/prefill.service.js');
          const prefillService = new PrefillService(pinia);
          const params = {
            ...instance.primaryParams,
            ...instance.overridePrefillBehavior,
          };

          await prefillService.postCreatePrefill(
            {
              insured: params,
              ...params,
            },
            { skipSetInsuredValues: true, canChangeStep: false },
          );
        }
      },
      saveAssistance() {
        return this.updateEapp({
          assist: {
            top_priority: this.assistance.topPriority,
            ltc_type: this.assistance.ltcType,
          },
        });
      },
      async saveQuoteStep(quote) {
        const insured = usePrimaryInsuredStore(pinia);
        const jInsured = useJointInsuredStore(pinia);

        const electronic_application = {
          rank: quote.rank,
        };

        if (jInsured.id) {
          await this.ensureRoleMatchForDualApplicants();
        }

        const finishedQuote = {
          ...quote,
          real_id: null,
          real_joint_id: null,
        };

        finishedQuote.real_id = await quotesService.setActiveQuote(quote.id, insured.id);

        if (this.isDualInsured) {
          finishedQuote.real_joint_id = await quotesService.setActiveQuote(
            quote.joint_insured_quote_id,
            jInsured.id,
          );
        }

        const result = await eAppService.updateElectronicApplication(electronic_application);

        this.setQuoteMetadata(finishedQuote);

        return result;
      },
      async initializeApplicationData(eApp, birthdate = null) {
        this.has_logged_in = true;
        const instance = useInstanceSettingsStore(pinia);
        const flow = useFlowStore(pinia);
        flow.updateStepRequirements(instance.data_requirements);
        // When a case is present, only case: {id, uuid} and birthdate are available.
        // Otherwise, it's the full eApp
        if (!eApp.case?.id || !eApp.case?.uuid) {
          return this.initializeEAppData(eApp);
        }

        const caseStore = useCaseStore(pinia);
        caseStore.id = eApp.case.id;
        caseStore.uuid = eApp.case.uuid;
        return caseStore.loginToCase(birthdate || eApp.birthdate);
      },
      async initializeEAppData(eApp) {
        this.setIds(eApp);
        this.apply_link = eApp.apply_link;

        const instance = useInstanceSettingsStore(pinia);
        const quoting = useQuotingStore(pinia);
        const flow = useFlowStore(pinia);

        instance.domain_key = eApp.approved_domain?.domain_key;
        instance.id = eApp.approved_domain?.id;

        this.initializeParties(eApp.parties);

        let primaryQuote, jointQuote;
        eApp.parties.forEach((p) => {
          if (p.application_roles.some((r) => r.role === INSURED)) primaryQuote = p.quote;
          if (p.application_roles.some((r) => r.role === JOINT_INSURED)) jointQuote = p.quote;
        });

        this.initializeQuoteData(primaryQuote, jointQuote);

        this.initializeExistingCoverages(eApp.electronic_application_cases || []);

        if (eApp.chat.opened) {
          const chat = await import('#src/stores/chat.js');
          const chatStore = chat.useChatStore(pinia);
          chatStore.setChatFromElectronicApplication(eApp.chat || {});
        }

        instance.domain_key = eApp.approved_domain?.domain_key;
        instance.agent = setAgentFromRequest(eApp.agent);
        this.initializeFromEApp(eApp);
        this.initializeCalculatorFromEapp(eApp);

        quoting.storeQuoteParametersFromEApp(eApp, primaryQuote);

        if (this.isDualInsured) {
          this.initializeUseEachotherAsBeneficiaries();
        }

        flow.updateStepRequirements(eApp.data_requirements);
      },
    },
  })(pinia, hot);
}
